import React, {useState, useEffect, Fragment} from 'react';
import endpoints from '../constants/endpoints';
import VerticalSlider from "./VerticalSlider";

function Home() {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(endpoints.home, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => err);
    }, []);

    const slideCount = data?.slideCount; // Your slide count
    const slidePath = data?.slidePath; // Base path for the images
    const imagesArray = Array.from({ length: slideCount }, (_, i) => `${slidePath}/slide-${i + 1}.png`);

    return (
        <Fragment>
            <div className="section-content-container">
                <VerticalSlider images={imagesArray}/>
            </div>
        </Fragment>
    );
}

export default Home;
