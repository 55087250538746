import React, {useEffect, useState} from 'react';
import '../css/verticalSlider.css';
import {useLocation} from "react-router";
import endpoints from "../constants/endpoints";
import {Container} from "react-bootstrap";
import Fade from "react-reveal";
import FallbackSpinner from "./FallbackSpinner";

const VerticalSlider = ({images}) => {

    const [imgs, setImgs] = useState(images)
    const location = useLocation();
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    const id = query.get('id');

    useEffect(() => {
        setImgs(images);
    }, [images])

    useEffect(() => {
        fetch(endpoints.projects, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((res) => {
                if (id !== null) {
                    console.log(res)
                    const slideCount = res.projects[id]?.slideCount; // Your slide count
                    const slidePath = res.projects[id]?.slidePath; // Base path for the images
                    let projImages = Array.from({length: slideCount}, (_, i) => `${slidePath}/slide-${i + 1}.png`);
                    setImgs(projImages);
                }
            })
            .catch((err) => err);
    }, []);


    return (
        <Container>
            {imgs ? (
                    <Fade>
                        <div className="slider-container">
                            <div
                                className="slides"
                                style={{transform: `translateY(-0%)`}}>
                                {imgs?.map((img, index) => (
                                    <div className="slide" key={index}>
                                        <img src={img} alt={`Slide ${index}`} style={{width: '100%', marginBottom:'5px'}}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Fade>
                )
                : <FallbackSpinner/>}
        </Container>

    );
};

export default VerticalSlider;
